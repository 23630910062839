import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEO"
import Heading from "../components/Heading"

import styles from "./404.module.scss"
import buttonStyles from "../components/button.module.scss"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />

    <Heading
      tag="h1"
      level="2"
      className={styles.heading}
    >
      404 - Page not found
    </Heading>
    <section className={styles.module}>
      <Link
        to="/collections/all"
        className={buttonStyles.btnDark}
      >
        Shop All
      </Link>
    </section>
  </>
)

export default NotFoundPage
